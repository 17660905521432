import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { ProjectsComponent } from './projects/projects.component';
import { AboutUsComponent } from './about.us/about.us.component';
import { WorkWithUsComponent } from './work.with.us/work.with.us.component';
import { LegalComponent } from './shared/components/legal/legal.component';
import { PrivacyComponent } from './shared/components/privacy/privacy.component';
import { KitDigitalComponent } from './kit_digital/kit_digital.component';


const routes: Routes =[
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'kit_digital', component: KitDigitalComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'work', component: WorkWithUsComponent },
  { path: 'legal', component:LegalComponent },
  { path: 'privacy', component:PrivacyComponent },
  { path: '**',  redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'})
    ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
