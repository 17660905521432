import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MailService } from '../../services/mail.service';
import Swal from 'sweetalert2';
import { sendMailDto } from '../../models/sendMailDto';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mailForm',
  templateUrl: './mail.form.component.html',
  styleUrls: ['./mail.form.component.scss']
})
export class MailFormComponent implements OnInit {

  focus: any;
  focus1: any;
  contactForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,    
    private _mail: MailService
  ) { }

  ngOnInit() {   
    this.projectForm();
  }

  projectForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
  }

  get f() { return this.contactForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.contactForm.invalid) {
        return;
    }

    var data = this.contactForm.value;

    var mail: sendMailDto = {
      company: environment.mail.company,
      fromName: environment.mail.fromName,
      fromEmail: environment.mail.fromEmail,
      toName: environment.mail.fromName,
      toEmail: environment.mail.fromEmail,
      subject: `Contacto desde Website: ${data.name} - ${data.email}`,
      body: data.message
    }
    
    this.sendMail(mail);
  }

  sendMail(mail: sendMailDto) {
    this._mail.sendMail(mail).subscribe({
      next: (res) => {
          Swal.fire(
          "Formulario de contacto",
          "Mensaje enviado correctamente",
          "success")          
        }
      ,
      error: (error: Error) => {
        Swal.fire(
          "Formulario de contacto",
          "Se ha producido un error en el envío del correo, por favor vuelva a intentarlo más tarde. Error: " + error.message,
          "error")
    }});
  }
}
