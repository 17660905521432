<form class="contact-form form-margin" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-md-6">
            <label style="margin-bottom: 0; font-weight: bold; color: #ffffffb3">{{ 'mailForm.name' | translate}}</label>
            <div [ngClass]="{'invalid-border':submitted && f.name.errors}">
                <div class="input-group" [ngClass]="{'input-group-focus':focus===true}">
                    <div class="input-group-prepend">
                        <span class="input-group-text" style="background-color: #c8c8c8"><i class="nc-icon nc-single-02"></i></span>
                    </div>
                    <input formControlName="name" type="text" class="form-control" (focus)="focus=true" (blur)="focus=false" style="color: black">
                </div>
            </div>
            <div *ngIf="submitted && f.name.errors" class="invalid">
                <div *ngIf="f.name.errors.required">{{ 'mailForm.nameRequired' | translate }}</div>
            </div>
        </div>
        <div class="col-md-6">
            <label style="margin-bottom: 0; font-weight: bold; color: #ffffffb3">{{ 'mailForm.emailLabel' | translate}}</label>
            <div [ngClass]="{'invalid-border':submitted && f.email.errors}">
                <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                    <div class="input-group-prepend">
                        <span class="input-group-text" style="background-color: #c8c8c8"><i class="nc-icon nc-email-85"></i></span>
                    </div>
                    <input formControlName="email" type="email" class="form-control" (focus)="focus1=true" (blur)="focus1=false" style="color: black">
                </div>
            </div>
            <div *ngIf="submitted && f.email.errors" class="invalid">
                <div *ngIf="f.email.errors.required">{{ 'mailForm.emailRequired' | translate }}</div>
                <div *ngIf="f.email.errors.email">{{ 'mailForm.emailNotValid' | translate }}</div>
            </div>
        </div>                   
    </div>
    <label style="margin-bottom: 0; font-weight: bold; color: #ffffffb3">{{ 'mailForm.message' | translate}}</label>
    <div [ngClass]="{'invalid-border':submitted && f.message.errors}">
        <textarea formControlName="message" class="form-control" rows="8" style="color: black"></textarea>
    </div>
    <div *ngIf="submitted && f.message.errors" class="invalid">
        <div *ngIf="f.message.errors.required">{{ 'mailForm.messageRequired' | translate }}</div>
    </div>
    <div class="row">
        <div class="col-md-4 mr-auto ml-auto" style="text-align: center;">
            <button class="btn btn-danger btn-lg btn-fill">{{ 'mailForm.send' | translate}}</button>
        </div>
    </div>
</form>