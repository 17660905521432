import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  animations: [
    trigger('enterTop', [      
      transition(':enter', [
          style({ transform: 'translateY(-50vh)' }),
          animate('500ms ease')
      ])
    ]),
    trigger('enterRight', [
      state('closed', style({ transform: 'translateX(100vw)' })),
      state('open', style({ transform: 'translateX(0vw)'})),
      transition('* => open', [
        animate('1000ms ease')
      ])
    ]),  
    trigger('enterLeft', [
      state('closed', style({ transform: 'translateX(-100vw)' })),
      state('open', style({ transform: 'translateX(0vw)'})),
      transition('* => open', [
        animate('1000ms ease')
      ])
    ]),  
  ],
  
})
export class ProjectsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
