<div class="wrapper" style="overflow-x: hidden;"> 
    <div @enterTop class="page-header page-header-xs" data-parallax="true" style="background-image: url('./../../../../assets/imgs/legal-image.png');">
        <div class="filter"></div>
    </div>    
    <div class="container" style="padding-bottom: 40px;">
        <div class="row">
            <div class="col-md-12 mr-auto ml-auto">
                <h2 class="text-center" style="margin-top: 50px; margin-bottom: 50px;">{{ 'legal.legalTitle' | translate}}</h2>                
                <div class="card-block" style="text-align: justify;">
                    <p><strong>Bienvenido al sitio web de Techview Solutions</strong></p>
                    <p>Para dar cumplimiento a lo dispuesto en la normativa aplicable en materia de servicios de sociedad de la información y de comercio electrónico, a continuación se indican los datos de información general de este sitio web:</p>
                    <p> </p>
                    <p><strong>Titular:</strong>  Techview Solutions, S.L.</p>
                    <p><strong>Correo electrónico:</strong> <a href="info@techview.es">  info@techview.es</a>.</p>
                    <p> </p>
                    <p><strong> 1.- Condiciones de uso</strong></p>
                    <p>Las presentes condiciones regulan el uso del sitio web donde se facilita al público información relativa a los servicios de Techview Solutions. Le rogamos lea detenidamente la información que le facilitamos. El hecho de acceder a este sitio web y utilizar los materiales contenidos en ella implica que usted ha leído y acepta, sin reserva alguna, estas condiciones. Techview Solutions se reserva el derecho de denegar, suspender, interrumpir o cancelar el acceso o la utilización, total o parcialmente, de este sitio web a aquellos usuarios o visitantes del mismo que incumplan cualquiera de las condiciones previstas en este Aviso Legal.</p>
                    <p> </p>
                    <p>Este sitio web contiene materiales, como artículos, estudios, descripción de proyectos, preparados por Techview Solutions con fines únicamente informativos. Techview Solutions le informa que estos materiales puedes ser modificados, desarrollados o actualizados en cualquier momento sin notificación previa.</p>
                    <p> </p>
                    <p><strong>2.- Derechos de Propiedad Intelectual e Industrial</strong></p>
                    <p>© 2020 Techview Solutions. Todos los derechos reservados.</p>
                    <p>La totalidad del contenido de este sitio web, entendiendo por contenido a titulo meramente enunciativo, textos, imágenes, ficheros, fotografías, logotipos, gráficos, marcas, iconos, combinaciones de colores, o cualquier otro elemento, su estructura y diseño, la selección y forma de presentación de los materiales incluidos en la misma, y el software, links y demás contenidos audiovisuales o sonoros, así como su diseño gráfico y códigos fuentes necesarios para su funcionamiento, acceso y utilización, están protegidos por derechos de propiedad industrial e intelectual, titularidad de Techview Solutions o de terceros, sin que puedan entenderse cedidos los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso del sitio Web.</p>
                    <p> </p>
                    <p>En particular, quedan prohibidas la reproducción, la transformación, distribución, comunicación pública, puesta a disposición del público y en general cualquier otra forma de explotación, por cualquier procedimiento, de todo o parte de los contenidos de este sitio web, así como de su diseño y la selección y forma de presentación de los materiales incluidos en la misma. Estos actos de explotación sólo podrán ser realizados si media la autorización expresa de Techview Solutions y siempre que se haga referencia a la titularidad de Techview Solutions de los indicados derechos de propiedad intelectual e industrial.</p>
                    <p> </p>
                    <p>Queda asimismo prohibido descompilar, desensamblar, realizar ingeniería inversa, sublicenciar o transmitir de cualquier modo, traducir o realizar obras derivadas de los programas de ordenador necesarios para el funcionamiento, acceso y utilización de este sitio web y de los servicios en él contenidos, así como realizar, respecto a todo o parte de tales programas, cualesquiera de los actos de explotación descritos en el párrafo anterior. El usuario del sitio web deberá abstenerse en todo caso de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistemas de seguridad que puedan estar instalados en el mismo.</p>
                    <p> </p>
                    <p>Las marcas, nombres comerciales o signos distintivos son titularidad de Techview Solutions o de terceros, sin que pueda entenderse que el acceso al sitio web atribuya ningún derecho sobre las citadas marcas, nombres comerciales y/o signos distintivos.</p>
                    <p> </p>
                    <p><strong>3.- Hiperenlaces</strong></p>
                    <p>Los hiperenlaces o links que contiene este sitio web pueden conducir al usuario a otros sitios y páginas web gestionados por terceros, sobre las que Techview Solutions no ejerce ningún tipo de control, Techview Solutions no responde ni de los contenidos ni del estado de dichos sitios y páginas web, y el acceso a las mismas a través de este sitio web tampoco implica que Techview Solutions recomiende o apruebe sus contenidos.</p>
                    <p> </p>
                    <p><strong>4.- Modificaciones</strong></p>
                    <p>Con el fin de mejorar el sitio web, Techview Solutions se reserva el derecho, en cualquier momento y sin previa notificación, a modificar, ampliar o suspender temporalmente la presentación, configuración, especificaciones técnicas y servicios del sitio web, de forma unilateral. Asimismo se reserva el derecho de modificar en cualquier momento las presentes condiciones de uso así como cualesquiera otras condiciones particulares.</p>
                    <p> </p>
                    <p><strong>5.- Exclusión de responsabilidad</strong></p>
                    <p>Quien utiliza este sitio web, lo hace por su propia cuenta y riesgo. Techview Solutions, sus socios, colaboradores, empleados y representantes no se responsabilizan de los errores u omisiones de los que pudieran adolecer los contenidos este sitio web u otros contenidos a los que se pueda acceder a través de la misma. Techview Solutions, sus socios, colaboradores, empleados y representantes tampoco podrán ser considerados responsables por cualesquiera daños derivados de la utilización de este sitio web, ni por cualquier actuación realizada sobre la base de la información que en ella se facilita.</p>
                    <p> </p>
                    <p>La información de este sitio web se proporciona sin garantía de ninguna clase, ni explícita ni implícita, y podrá cambiarse o actualizarse sin previo aviso. Techview Solutions no garantiza la ausencia de virus, gusanos u otros elementos informáticos lesivos que pudieran causar daños o alteraciones en el sistema informático, en los documentos electrónicos o en los ficheros del usuario de este sitio web. En consecuencia, Techview Solutions no responde por los daños y perjuicios que tales elementos pudieran ocasionar al usuario o a terceros. Así mismo no se responsabiliza ni garantiza la disponibilidad y continuidad en el acceso a este sitio web o que el mismo esté libre de errores, correspondiendo al usuario del sitio Web, en cualquier caso, la obligación de disponer herramientas adecuadas para la detección y desinfección de programas informáticos dañinos o lesivos.</p>
                    <p> </p>
                    <p>El usuario responderá de los daños y perjuicios de toda naturaleza que Techview Solutions pueda sufrir como consecuencia del incumplimiento de cualquiera de las obligaciones a las que queda sometido por las presentes condiciones. El usuario es consciente y acepta voluntariamente que el uso de cualesquiera contenidos de este sitio web tiene lugar, en todo caso, bajo su única y exclusiva responsabilidad.</p>
                    <p> </p>
                    <p><strong>6.- Comunicación de actividades de carácter ilícito e inadecuado</strong></p>
                    <p>En el caso de que cualquier usuario del sitio web tuviera conocimiento de que los hiperenlaces remiten a páginas cuyos contenidos o servicios son ilícitos, nocivos, denigrantes, violentos o contrarios a la moral podrá ponerse en contacto con Techview Solutions indicando los siguientes extremos:</p>
                    <p> </p>
                    <p>Datos personales del comunicante: nombre, dirección, número de teléfono y dirección de correo electrónico;</p>
                    <p> </p>
                    <p>Descripción de los hechos que revelan el carácter ilícito o inadecuado del hiperenlace;</p>
                    <p> </p>
                    <p>En el supuesto de violación de derechos, tales como propiedad intelectual e industrial, los datos personales del titular del derecho infringido cuando sea persona distinta del comunicante. Asimismo deberá aportar el título que acredite la legitimación del titular de los derechos y, en su caso, el de representación para actuar por cuenta del titular cuando sea persona distinta del comunicante;</p>
                    <p> </p>
                    <p>La recepción por parte de Techview Solutions de la comunicación prevista en esta cláusula no supondrá, según lo dispuesto en la normativa relativa a servicios de la sociedad de la información y de comercio electrónico, el conocimiento efectivo de las actividades y/o contenidos indicados por el comunicante.</p>
                    <p> </p>
                    <p><strong>7.- Legislación</strong></p>
                    <p>Las presentes condiciones de uso se rigen en todos y cada uno de sus extremos por la legislación española.</p>
                    <p> </p>
                    <p>Datos personales del comunicante: nombre, dirección, número de teléfono y dirección de correo electrónico;</p>
                    <p>Descripción de los hechos que revelan el carácter ilícito o inadecuado del hiperenlace;</p>
                    <p>En el supuesto de violación de derechos, tales como propiedad intelectual e industrial, los datos personales del titular del derecho infringido cuando sea persona distinta del comunicante. Asimismo deberá aportar el título que acredite la legitimación del titular de los derechos y, en su caso, el de representación para actuar por cuenta del titular cuando sea persona distinta del comunicante;</p>
                    <p>La recepción por parte de Techview Solutions de la comunicación prevista en esta cláusula no supondrá, según lo dispuesto en la normativa relativa a servicios de la sociedad de la información y de comercio electrónico, el conocimiento efectivo de las actividades y/o contenidos indicados por el comunicante.</p>
                    <p> </p>
                    <p><strong>8.- Comunicación de actividades de carácter ilícito e inadecuado</strong></p>
                    <p>En el caso de que cualquier usuario del sitio web tuviera conocimiento de que los hiperenlaces remiten a páginas cuyos contenidos o servicios son ilícitos, nocivos, denigrantes, violentos o contrarios a la moral podrá ponerse en contacto con Techview Solutions indicando los siguientes extremos:</p>
                    <p> </p>
                    <p>Datos personales del comunicante: nombre, dirección, número de teléfono y dirección de correo electrónico;</p>
                    <p>Descripción de los hechos que revelan el carácter ilícito o inadecuado del hiperenlace;</p>
                    <p>En el supuesto de violación de derechos, tales como propiedad intelectual e industrial, los datos personales del titular del derecho infringido cuando sea persona distinta del comunicante. Asimismo deberá aportar el título que acredite la legitimación del titular de los derechos y, en su caso, el de representación para actuar por cuenta del titular cuando sea persona distinta del comunicante;</p>
                    <p>La recepción por parte de Techview Solutions de la comunicación prevista en esta cláusula no supondrá, según lo dispuesto en la normativa relativa a servicios de la sociedad de la información y de comercio electrónico, el conocimiento efectivo de las actividades y/o contenidos indicados por el comunicante.</p>
                    <p> </p>
                </div>
            </div>
        </div>
    </div>
</div>