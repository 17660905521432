<div class="wrapper" style="overflow-x: hidden;"> 
    <div @enterTop class="page-header page-header-xs" data-parallax="true" style="background-image: url('./../../../../assets/imgs/privacy-image.png');">
        <div class="filter"></div>
    </div>    
    <div class="container" style="padding-bottom: 40px;">
        <div class="row">
            <div class="col-md-12 mr-auto ml-auto">
                <h2 class="text-center" style="margin-top: 50px; margin-bottom: 50px;">{{ 'policy.policyTitle' | translate}}</h2>                
               
                <div class="card-block" style="text-align: justify;">
                    <p><strong>¿Quién es el Responsable del Tratamiento de sus datos?</strong><br />
                     </p>
                    <p><strong>Identidad: </strong> Techview Solutions, S.L.<br /><strong>Dirección correo electrónico: </strong> <a href="info@techview.es"> info@techview.es</a><br /><br />
                     </p>
                    <p><strong>¿Con qué finalidad tratamos sus datos personales?</strong><br />
                     </p>
                    <p>La presente Política de Privacidad regula el tratamiento de los datos recabados a través del Sitio Web, si bien determinadas secciones o formularios regulan de forma específica el tratamiento de la información obtenida a través de los mismos.<br />
                     </p>
                    <p>Sus datos personales podrán serán tratados para:<br />
                     </p>
                    <p>• Llevar a cabo las tareas de análisis de tráfico de la web. <br />
                    • Gestionar las consultas o peticiones recibidas, tales como solicitudes de información, documentos o propuestas.<br />
                    • Gestionar los procesos de selección de personal, presentes o futuros, llevados a cabo por Techview Solutions. <br />                    
                    • Gestionar el envío de comunicaciones comerciales o newsletters a través de cualquier canal, incluyendo medios electrónicos (tales como correo electrónico y notificaciones web) sobre noticias, cursos, actividades, eventos propios o de terceros, productos, servicios, ofertas y promociones relacionados con las actividades desarrolladas por Techview Solutions. <br />
                    • Gestionar el envío de comunicaciones relativas a acciones o eventos de recruiting. </p>
                    <p> </p>
                    <p>Adicionalmente le informamos acerca del posible tratamiento de datos de usuarios de redes sociales a través de los perfiles corporativos de Techview Solutions con las finalidades permitidas en cada red social, incluyendo fines analíticos.<br />
                     </p>
                    <p><strong>Procedencia y Categorías de datos tratados</strong><br />
                     </p>
                    <p>Los datos personales que sobre usted tratemos proceden de la información que usted mismo nos facilite mediante la cumplimentación de los formularios que encontrará en la web de Techview Solutions o a través del medio utilizado para solicitar formar parte de nuestros procesos de selección. <br />
                     </p>
                    <p>En concreto trataremos los siguientes datos:<br />
                     </p>
                    <p>• Datos identificativos: nombre, apellidos, documento identificativo, dirección postal, dirección de correo electrónico, etc. <br />
                    • Datos curriculares aportados en la gestión de procesos de selección.<br />
                    • Datos aportados en la gestión de consultas o solicitudes.<br />
                    • Datos aportados en la gestión de denuncias.</p>
                    <p>
                    En algunos casos los datos curriculares de los candidatos podrían proceder de terceros tales como portales de empleo, redes sociales profesionales, empresas de trabajo temporal, consultoras de selección o headhunters, así como centros docentes o universidades. </p>
                    <p> </p>
                    <p><strong>¿Por cuánto tiempo conservaremos sus datos?</strong><br />
                     </p>
                    <p>Sus datos personales se conservarán durante el plazo necesario para la finalidad para la que se recabaron. <br />
                    En relación con el envío de comunicaciones comerciales o relativas a recruiting, los datos serán tratados hasta que el interesado solicite su supresión. </p>
                    <p> </p>
                    <p><strong>¿Cuál es la legitimación para el tratamiento de sus datos?</strong><br />
                     </p>
                    <p>Tratamos los datos de los usuarios sólo si contamos con su consentimiento expreso e informado para hacerlo, y siempre los trataremos conforme a las finalidades señaladas en el epígrafe relativo a la finalidad. </p>
                    <p> </p>
                    <p><strong>¿A qué destinatarios se comunicarán sus datos?</strong><br />
                     </p>                    
                    <p>Adicionalmente le informamos que sus datos podrían ser comunicados a Encargados del Tratamiento, tales como los siguientes proveedores tecnológicos, algunos de los cuales disponen de centros de proceso y/o soporte ubicados fuera Espacio Económico Europeo:<br />
                     </p>
                    <p>- <strong>AMAZON WEB SERVICES,</strong> con servidores en la Unión Europea y adherido al acuerdo EU-US Privacy Shield, para la gestión de información relacionada con la prestación del servicio.</p>
                    <p> </p>
                    <p>- <strong>SALESFORCE</strong>, ubicado en EE.UU. y adherido al acuerdo EU-US Privacy Shield, para la prestación de servicios tales como la creación de formularios en línea. Le garantizamos que cuando sus datos pudieran salir del Espacio Económico Europeo, mantendrán el mismo nivel de protección en base al cumplimiento de las disposiciones previstas en la normativa europea de protección de datos. En este sentido, las transferencias internacionales de datos se realizarán (i) a países con un adecuado nivel de protección declarado por la Comisión Europea (ii) en base a la aportación de garantías adecuadas tales como cláusulas contractuales tipo o normas corporativas vinculantes o (iii) en virtud de la autorización de la autoridad de control competente u otros supuestos previstos en la normativa. </p>
                    <p> </p>
                    <p><strong>Derechos</strong><br />
                     </p>
                    <p>Usted es titular de los siguientes derechos sobre sus datos de carácter personal:<br />
                     </p>
                    <p>• Derecho a solicitar el acceso a los datos personales. <br />
                    • Derecho a solicitar su rectificación o supresión. <br />
                    • Derecho a oposición. <br />
                    • Derecho a solicitar la limitación de su tratamiento. <br />
                    • Derecho a la portabilidad de los datos. </p>
                    <p> </p>
                    <p>Para ejercitar los citados derechos, puede enviarnos un correo electrónico a <a href="mailto:info@techview.es">info@techview.es</a> adjuntando copia de su documento identificativo y el detalle del derecho que desea ejercitar. </p>
                    <p> </p>
                    <p>Asimismo, le informamos que puede usted solicitar la tutela de sus derechos ante la Agencia Española de Protección de Datos con sede en la Calle de Jorge Juan, 6, 28001 Madrid.</p>
                    <p> </p>
                </div>                   
            </div>
        </div>
    </div>
</div>