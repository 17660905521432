<aside id="sticky-social" @fromTop class="d-none d-sm-block">
    <ul>
        <li><a class="entypo-mail" [routerLink]="['/contact']" style="cursor: pointer;"><span>{{ 'navbar.contact' | translate}}</span></a></li>
        <li><a [href]="facebookUrl" class="entypo-facebook" target="_blank"><span>Facebook</span></a></li>
        <li><a [href]="twitterUrl" class="entypo-twitter" target="_blank"><span>Twitter</span></a></li>        
        <li><a [href]="linkedInUrl" class="entypo-linkedin" target="_blank"><span>LinkedIn</span></a></li>
        <!--<li><a href="https://instagram.com" class="entypo-instagrem" target="_blank"><span>Instagram</span></a></li>
        <li><a href="#" class="entypo-stumbleupon" target="_blank"><span>StumbleUpon</span></a></li>
        <li><a href="#" class="entypo-pinterest" target="_blank"><span>Pinterest</span></a></li>
        <li><a href="#" class="entypo-flickr" target="_blank"><span>Flickr</span></a></li>
        <li><a href="#" class="entypo-tumblr" target="_blank"><span>Tumblr</span></a></li>
        <li><a href="#" class="entypo-gplus" target="_blank"><span>Google+</span></a></li>-->
    </ul>
</aside> 

<!--<div style="height:1024px;"><h1>Sticky Social Button</h1></div>
<div id="sticky-social">
<ul>
<li><a href="#" class="entypo-gplus" target="_blank"><span>Google Plus</span></a></li>
<li><a href="#" class="entypo-twitter" target="_blank"><span>Twitter</span></a></li>
<li><a href="#" class="entypo-facebook" target="_blank"><span>Facebook</span></a></li>
</ul>
</div>-->