<agm-map 
    [latitude]='latitude' 
    [longitude]='longitude' 
    [mapTypeId]='mapType'    
    [scrollwheel]="null"
    [zoom]="15">
    <agm-marker 
        [latitude]="latitude" 
        [longitude]="longitude">
    </agm-marker>
</agm-map>