<div class="wrapper" style="overflow-x: hidden;">   
    <div @enterTop class="page-header page-header-xs" data-parallax="true" style="background-image: url('./../../assets/imgs/work-image.png');">
        <div class="filter"></div>
    </div>
    <div class="section profile-content">
        <div class="container">
            <div class="owner">                
                <div class="name">
                    <h3 class="title" style="margin-bottom: 70px;">{{ 'work.title' | translate }}<br /></h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ml-auto mr-auto text-center" style="margin-bottom: 25px;">
                    <p style="margin-bottom: 50px;">{{ 'work.noVacancyText' | translate }}</p>
                    <br />
                    <a href="mailto:info@techview.es?Subject=Curriculum" 
                    class="btn btn-outline-default btn-round">
                    <i class="fa fa-envelope"></i> 
                    {{ 'work.send' | translate }}
                    </a>
                </div>
            </div>
            <br/>            
        </div>
    </div> 
</div>
