<!--<div class="page-header" data-parallax="true" style="position: absolute; top: 0px;"></div>-->
<div style="overflow-x: hidden;">
    <div @enterTop class="page-header" data-parallax="true" style="background-image: url('./../../assets/imgs/landing.jpg');">
        <div class="filter"></div>
        <div class="container" >
            <div class="motto text-center" style="margin-top: 25vh;">
                <h2 style="max-width: 70%; margin: auto;">{{ 'home.title' | translate}}</h2>
                <h3 class="slogan">{{ 'home.slogan' | translate}}</h3>
                <h5>{{ 'home.signature' | translate}}</h5>
                <br />           
            </div>
        </div>
    </div>
    <div class="main">
        <div class="section text-center">
            <div class="container">
                <!--<div #mainText class="row" style="margin-bottom: 50px;" [@enterRight]="mainState? 'open' : 'closed'">
                    <div class="col-md-8 mr-auto ml-auto">
                        <h2 class="title">{{ 'home.firstSection.title' | translate }}</h2>
                        <h5 class="description">
                            {{ 'home.firstSection.text' | translate }}
                        </h5>
                        <br>                        
                    </div>
                </div>-->
                <div #secondSection class="row" [@enterRight]="secondState ? 'open' : 'closed'">
                    <div class="col-md-12 mr-auto ml-auto">
                        <h2 class="title">{{ 'home.secondSection.title' | translate:params }}</h2>
                    </div>
                </div>
                <br/><br/>
                <div class="row">
                    <div class="col-md-6">
                        <div #firstSub class="info" [@appear]="firstSubState ? 'open':'closed'">
                            <div class="icon icon-danger">                                
                                <img class="nc-icon nc-album-2" src="./../../assets/imgs/icons/ear.png" style="width: 80px; padding-top: 15px; padding-bottom: 10px;">
                            </div>
                            <div class="description">
                                <h4 class="info-title">{{ 'home.secondSection.firstSubSection.title' | translate }}</h4>                                
                                <p class="description">{{ 'home.secondSection.firstSubSection.text' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div #secondSub class="info" [@appear]="secondSubState ? 'open':'closed'">
                            <div class="icon icon-danger">
                                <span style="font-size: 70px;">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                            <div class="description">
                                <h4 class="info-title">{{ 'home.secondSection.secondSubSection.title' | translate }}</h4>
                                <p class="description">{{ 'home.secondSection.secondSubSection.text' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div #thirdSub class="info" [@appear]="thirdSubState ? 'open':'closed'">
                            <div class="icon icon-danger">
                                <span style="font-size: 70px;">
                                    <i class="fa fa-wrench"></i>
                                </span>                                
                            </div>
                            <div class="description">
                                <h4 class="info-title">{{ 'home.secondSection.thirdSubSection.title' | translate }}</h4>
                                <p class="description">{{ 'home.secondSection.thirdSubSection.text' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div #fourthSub class="info" [@appear]="fourthSubState ? 'open':'closed'">
                            <div class="icon icon-danger">
                                <span style="font-size: 70px;">
                                    <i class="fa fa-graduation-cap"></i>
                                </span>                                
                            </div>
                            <div class="description">
                                <h4 class="info-title">{{ 'home.secondSection.fourthSubSection.title' | translate }}</h4>
                                <p class="description">{{ 'home.secondSection.fourthSubSection.text' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row bottom-row">
                        <div #fifthSub class="col-md-8 mr-auto ml-auto" [@enterRight]="fifthSubState? 'open' : 'closed'">
                            <div class="icon icon-danger">
                                <span style="font-size: 70px;">
                                    <i class="fa fa-users"></i>
                                </span>                                
                            </div>
                            <div class="description">
                                <h4 class="info-title">{{ 'home.secondSection.fifthSubSection.title' | translate }}</h4>
                                <p class="description">{{ 'home.secondSection.fifthSubSection.text' | translate }}</p>
                            </div>                   
                        </div>
                    </div>
                    <!--<div class="row" style="margin-bottom: 50px;" >
                        <div #firstNews class="col-md-8 mr-auto ml-auto" [@news]="firstNewsState == 1 ? 'open' : 'closed'" [@newsDone]="firstNewsState == 2? 'done' : 'open'">
                            <h2 class="title">{{ 'home.firstSection.title' | translate }}</h2>
                            <h5 class="description">
                                {{ 'home.firstSection.text' | translate }}
                            </h5>
                            <br>                        
                        </div>
                    </div>-->
                </div>
            </div>
        </div>    
    </div>
</div>