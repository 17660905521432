<footer class="footer">      
    <div class="section section-dark text-center min-padding">
        <div class="container">                   
            <div class="row" style="padding-bottom: 15px;">  
                <div class="col-md-8 mr-auto ml-auto ng-tns-c70-0">
                    <h5 style="font-weight: bold; color: #ffffffb3">{{ 'footer.contact' | translate}}</h5>
                    <app-mailForm></app-mailForm>
                
                    <div class="col-md-6 ng-tns-c70-0" style="float: left;">
                        <div class="card  card-plain">                        
                            <h6 class="card-category" style="margin-bottom: 10px;">{{ 'footer.emailLabel' | translate}}</h6>
                            <p class="card-description text-center">
                                <a href="mailto:info@techview.es">info@techview.es</a>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 ng-tns-c70-0" style="float: left;">
                        <div class="card  card-plain">
                            <div class="author">                                
                                <h6 class="card-category" style="margin-bottom: 10px;">{{ 'footer.information' | translate}}</h6>
                            </div>            
                            <p class="card-description" style="margin-bottom: 20px;">
                                <a [routerLink]="['/privacy']">{{ 'footer.privacyPolicy' | translate}}</a><br>
                                <a [routerLink]="['/legal']">{{ 'footer.legalNotice' | translate}}</a><br>  
                            </p>   
                        </div>
                    </div>
                </div>
            </div>                
            <div class="credits ml-auto" style="margin-top: -40px;">
                <span class="copyright">
                    © {{date | date: 'yyyy'}}, made by TechView Solutions
                </span>
            </div>
        </div>
    </div>
</footer>
