import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('enterRight', [
      state('closed', style({ transform: 'translateX(100vw)' })),
      state('open', style({ transform: 'translateX(0vw)' })),
      transition('* => open', [
        animate('1000ms ease')
      ])
    ]),  
    trigger('appear', [
      state('closed', style({ transform: 'scale(0.5)', opacity: '0' })),
      state('open', style({ transform: 'scale(1)', opacity: '1' })),
      transition('* => open', [
        animate('500ms ease')
      ])
    ]),    
    trigger('news', [
      state('closed', style({ transform: 'scale(0.1)', opacity: '0', backgroundImage: 'url("./../../assets/imgs/newspaper.png")' })),
      state('open', style({ transform: 'scale(1.3) rotate(2000dg)', opacity: '1', backgroundImage: 'url("./../../assets/imgs/newspaper.png")' })),
      transition('* => open', [
        animate('1000ms ease')
      ])
    ]),   
    trigger('newsDone', [
      state('open',  style({ transform: 'scale(1.3) rotate(2000dg)', opacity: '1', backgroundImage: 'url("./../../assets/imgs/newspaper.png")' })),
      state('done', style({ transform: 'scale(1)' })),
      transition('* => opedonen', [
        animate('500ms 500ms ease')
      ])
    ]),   
    trigger('enterTop', [      
      transition(':enter', [
        style({ transform: 'translateY(-110vh)' }),
        animate('1000ms ease')
      ]),
      transition('* => void', [
        animate('500ms 1000ms ease', style({ transform: 'translateY(-110vh)' }))
    ])
    ])
  ],
})


export class HomeComponent implements OnInit {
  focus: any;
  focus1: any;
  public params = {
    symbol: '"'
  }

  // animation variables //
  public mainState = false;
  public secondState = false;
  public divState = 'closed';
  public firstSubState = false;
  public secondSubState = false;
  public thirdSubState = false;
  public fourthSubState = false;
  public fifthSubState = false;
  public firstNewsState = 0;

  private defaultTimeOut = 250;
  private bodyRect;
  private mainTextOffset;
  private secondSectionOffset;
  private firstSubOffset;
  private secondSubOffset;
  private thirdSubOffset;
  private fourthSubOffset;
  private fifthSubOffset;
  private firstNewsOffset;
  private screenHeight;
  private screenWidth;
  private jumpOffset;

  //@ViewChild('mainText') private mainText: ElementRef;
  @ViewChild('secondSection') private secondSection: ElementRef;
  @ViewChild('firstSub') private firstSub: ElementRef;
  @ViewChild('secondSub') private secondSub: ElementRef;
  @ViewChild('thirdSub') private thirdSub: ElementRef;
  @ViewChild('fourthSub') private fourthSub: ElementRef;
  @ViewChild('fifthSub') private fifthSub: ElementRef;
  @ViewChild('firstNews') private firstNews: ElementRef;

  constructor(    
    private renderer : Renderer2, 
  ) { }

  ngOnInit() {

    this.renderer.listen('window', 'scroll', (event) => {
      const number = window.scrollY; 
      this.getPositions();

      if (number > (this.mainTextOffset + this.jumpOffset - this.screenHeight)) {
        this.mainState = true;  
      }

      if (number > (this.secondSectionOffset + this.jumpOffset - this.screenHeight)) {
        this.secondState = true;  
      }

      if (number > (this.firstSubOffset + this.jumpOffset - this.screenHeight)) {
        this.firstSubState = true;  
      }

      if (number > (this.secondSubOffset + this.jumpOffset - this.screenHeight)) {
        var timeOut = this.screenWidth > 760 ? this.defaultTimeOut : 1;
        setTimeout(()=>{ this.secondSubState = true;}, timeOut);
      }

      if (number > (this.thirdSubOffset + this.jumpOffset - this.screenHeight)) {
        this.thirdSubState = true;  
      }

      if (number > (this.fourthSubOffset + this.jumpOffset - this.screenHeight)) {
        var timeOut = this.screenWidth > 760 ? this.defaultTimeOut : 1;
        setTimeout(()=>{ this.fourthSubState = true;}, timeOut);
      }

      if (number > (this.fifthSubOffset + this.jumpOffset - this.screenHeight)) {
        this.fifthSubState = true;  
      }

      /*if (number > (this.firstNewsOffset + this.jumpOffset - this.screenHeight)) {
        this.fifthSubState = true;  
      }*/
    })
  } 
  getPositions() {
    
    this.bodyRect = document.body.getBoundingClientRect();
    //this.mainTextOffset = this.mainText.nativeElement.getBoundingClientRect().top - this.bodyRect.top;
    this.secondSectionOffset = this.secondSection.nativeElement.getBoundingClientRect().top - this.bodyRect.top;
    this.firstSubOffset = this.firstSub.nativeElement.getBoundingClientRect().top - this.bodyRect.top;
    this.secondSubOffset = this.secondSub.nativeElement.getBoundingClientRect().top - this.bodyRect.top;
    this.thirdSubOffset= this.thirdSub.nativeElement.getBoundingClientRect().top - this.bodyRect.top;
    this.fourthSubOffset = this.fourthSub.nativeElement.getBoundingClientRect().top - this.bodyRect.top;
    this.fifthSubOffset = this.fifthSub.nativeElement.getBoundingClientRect().top - this.bodyRect.top;
    //this.firstNewsOffset = this.firstNews.nativeElement.getBoundingClientRect().top - this.bodyRect.top;

    this.screenHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
    this.screenWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
    this.jumpOffset = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) / 4;
  }
}
