import { Component, OnInit } from '@angular/core';
import { transition, trigger, style, animate } from '@angular/animations';

@Component({
  selector: 'app-about.us',
  templateUrl: './about.us.component.html',
  styleUrls: ['./about.us.component.scss'],
  animations: [
    trigger('enterTop', [      
      transition(':enter', [
          style({ transform: 'translateY(-50vh)' }),
          animate('500ms ease')
      ])
    ]),
    trigger('enterBottom', [      
      transition(':enter', [
          style({ transform: 'translateY(100vh)' }),
          animate('500ms ease')
      ])
    ])
  ],
})
export class AboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
