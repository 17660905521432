<div class="wrapper" style="overflow-x: hidden;">    
    <div @enterTop class="page-header page-header-xs" data-parallax="true" style="background-image: url('./../../assets/imgs/services-image.png');">
        <div class="filter"></div>
    </div>
    <div class="main">
        <div class="section text-center">
            <div #secondSection class="row" [@enterRight]="titleState ? 'open':'closed'">
                <div class="col-md-12 mr-auto ml-auto">
                    <h2 class="title">{{ 'services.title' | translate }}</h2>
                </div>
            </div>
            <div class="row">
                <div class="container">                   
                    <div #first class="col-md-6" [@enterLeft]="firstState ? 'open':'closed'">
                        <h3 class="title">{{ 'services.first.title' | translate }}</h3>
                        <h5 class="description">
                            {{ 'services.first.text' | translate }}
                        </h5>
                        <br>                        
                    </div>
                    <div #second class="col-md-6" [@enterRight]="secondState ? 'open':'closed'">
                        <h3 class="title">{{ 'services.second.title' | translate }}</h3>
                        <h5 class="description">
                            {{ 'services.second.text' | translate }}
                        </h5>
                        <br>                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="container">                    
                    <div #third class="col-md-6" [@enterLeft]="thirdState ? 'open':'closed'">
                        <h3 class="title">{{ 'services.third.title' | translate }}</h3>
                        <h5 class="description">
                            {{ 'services.third.text' | translate }}
                        </h5>
                        <br>
                    </div>                
                    <div #fourth class="col-md-6" [@enterRight]="fourthState ? 'open':'closed'">
                        <h3 class="title">{{ 'services.fourth.title' | translate }}</h3>
                        <h5 class="description">
                            {{ 'services.fourth.text' | translate }}
                        </h5>
                        <br>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="container">
                    <div #fifth class="col-md-6" [@enterLeft]="fifthState ? 'open':'closed'">
                        <h3 class="title">{{ 'services.fifth.title' | translate }}</h3>
                        <h5 class="description">
                            {{ 'services.fifth.text' | translate }}
                        </h5>
                        <br> 
                    </div>               
                    <div #sixth class="col-md-6" [@enterRight]="sixthState ? 'open':'closed'">
                        <h3 class="title">{{ 'services.sixth.title' | translate }}</h3>
                        <h5 class="description">
                            {{ 'services.sixth.text' | translate }}
                        </h5>
                        <br>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="container">                
                    <div #seventh class="col-md-6" [@enterLeft]="seventhState ? 'open':'closed'">
                        <h3 class="title">{{ 'services.seventh.title' | translate }}</h3>
                        <h5 class="description">
                            {{ 'services.seventh.text' | translate }}
                        </h5>
                        <br> 
                    </div>                
                    <div #eighth class="col-md-6" [@enterRight]="eighthState ? 'open':'closed'">
                        <h3 class="title">{{ 'services.eighth.title' | translate }}</h3>
                        <h5 class="description">
                            {{ 'services.eighth.text' | translate }}
                        </h5>
                        <br>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>