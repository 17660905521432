import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-work.with.us',
  templateUrl: './work.with.us.component.html',
  styleUrls: ['./work.with.us.component.scss'],
  animations: [
    trigger('enterTop', [      
      transition(':enter', [
          style({ transform: 'translateY(-50vh)' }),
          animate('500ms ease')
      ])
    ])
  ],
})

export class WorkWithUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
