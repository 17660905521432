import { Component, OnInit, ViewChild, Inject, ElementRef, Renderer2} from '@angular/core';
import { LanguageService } from './shared/services/language.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private _router: Subscription;
  public isScrollTop: boolean = false;
  public logoImage: string = "../../../../assets/imgs/Logo_TechView_White_White.png";
  @ViewChild(NavbarComponent) navbar: NavbarComponent;

  constructor(
    private languageService: LanguageService,
    private renderer : Renderer2, 
    private router: Router, 
    @Inject(DOCUMENT,) private document: any, 
    private element : ElementRef, 
    public location: Location
  ) {
    this.initializeWeb();
  }

  initializeWeb() {
    this.languageService.setInitialLanguage();
  }

  ngOnInit() {  
    var navbar : HTMLElement = this.element.nativeElement.children[0].children[0];
    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (window.outerWidth > 991) {
            window.document.children[0].scrollTop = 0;
        } else {
            window.document.activeElement.scrollTop = 0;
        }
        this.navbar.sidebarClose();
    });

    this.renderer.listen('window', 'scroll', (event) => {
        const number = window.scrollY;
        if (number > 150 || window.pageYOffset > 150) {
            // add logic
            navbar.classList.remove('navbar-transparent');
            this.logoImage = ".../../../../assets/imgs/Logo_TechView_White.png";
        } else {
            // remove logic
            navbar.classList.add('navbar-transparent');
            this.logoImage = "../../../../assets/imgs/Logo_TechView_White_White.png";
        }

        if (number > window.innerHeight) {
          this.isScrollTop = true;
        } else {
          this.isScrollTop = false;
        }
        

    });
    var ua = window.navigator.userAgent;
    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        var version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
    if (version) {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('ie-background');

    }
  }
  removeFooter() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice( 1 );
    if(titlee === 'signup' || titlee === 'nucleoicons'){
        return false;
    }
    else {
        return true;
    }
  }
}
