<div class="wrapper" style="overflow-x: hidden;">    
    <div @enterTop class="page-header page-header-xs" data-parallax="true" style="background-image: url('./../../assets/imgs/services.png');">
        <div class="filter"></div>
    </div>
    <div @enterTop class="kit-digital-logo-container">
        <img class="kit-digital-logo"  src='./../../assets/imgs/Logo-digitalizadores.png'/>
    </div>
    
    <div class="main">        
        <div class="section text-center">
            <div #secondSection class="row" [@enterRight]="titleState ? 'open':'closed'">
                <div class="col-md-12 mr-auto ml-auto">
                    <h2 class="title">{{ 'kitDigital.title' | translate }}</h2>
                </div>
            </div>
            <div class="row">
                <div class="container">                   
                    <div #first class="col-md-6" [@enterLeft]="firstState ? 'open':'closed'">
                        <h3 class="title">{{ 'kitDigital.first.title' | translate }}</h3>
                        <h5 class="description saltos-linea">
                            {{ 'kitDigital.first.text' | translate }}
                        </h5>
                        <br>                        
                    </div>
                    <div #second class="col-md-6" [@enterRight]="secondState ? 'open':'closed'">
                        <h3 class="title">{{ 'kitDigital.second.title' | translate }}</h3>
                        <h5 class="description saltos-linea">
                            {{ 'kitDigital.second.text' | translate }}
                        </h5>
                        <br>                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="container">                    
                    <div #third class="col-md-6" [@enterLeft]="thirdState ? 'open':'closed'">
                        <h3 class="title">{{ 'kitDigital.third.title' | translate }}</h3>
                        <h5 class="description saltos-linea">
                            {{ 'kitDigital.third.text' | translate }}
                        </h5>
                        <br>
                    </div>                
                    <div #fourth class="col-md-6" [@enterRight]="fourthState ? 'open':'closed'">
                        <h3 class="title">{{ 'kitDigital.fourth.title' | translate }}</h3>
                        <h5 class="description saltos-linea">
                            {{ 'kitDigital.fourth.text' | translate }}
                        </h5>
                        <br>
                    </div>
                </div>
            </div>
            <!--<div class="row">
                <div class="container">
                    <div #fifth class="col-md-6" [@enterLeft]="fifthState ? 'open':'closed'">
                        <h3 class="title">{{ 'kitDigital.fifth.title' | translate }}</h3>
                        <h5 class="description">
                            {{ 'kitDigital.fifth.text' | translate }}
                        </h5>
                        <br> 
                    </div>               
                    <div #sixth class="col-md-6" [@enterRight]="sixthState ? 'open':'closed'">
                        <h3 class="title">{{ 'kitDigital.sixth.title' | translate }}</h3>
                        <h5 class="description">
                            {{ 'kitDigital.sixth.text' | translate }}
                        </h5>
                        <br>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="container">                
                    <div #seventh class="col-md-6" [@enterLeft]="seventhState ? 'open':'closed'">
                        <h3 class="title">{{ 'kitDigital.seventh.title' | translate }}</h3>
                        <h5 class="description">
                            {{ 'kitDigital.seventh.text' | translate }}
                        </h5>
                        <br> 
                    </div>                
                    <div #eighth class="col-md-6" [@enterRight]="eighthState ? 'open':'closed'">
                        <h3 class="title">{{ 'kitDigital.eighth.title' | translate }}</h3>
                        <h5 class="description">
                            {{ 'kitDigital.eighth.text' | translate }}
                        </h5>
                        <br>   
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</div>