<div class="wrapper" style="overflow-x: hidden;"> 
    <div @enterTop class="page-header page-header-xs" data-parallax="true" style="background-image: url('./../../assets/imgs/projects-color.png');">
        <div class="filter"></div>
    </div>
    <div class="section profile-content">
        <div class="container">
            <div class="owner">
                <div class="avatar">
                </div>
                <div class="name">
                    <h4 class="title">Jane Faker<br /></h4>
                    <h6 class="description">Music Producer</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ml-auto mr-auto text-center">
                    <p>An artist of considerable range, Jane Faker — the name taken by Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs and records all of his own music, giving it a warm, intimate feel with a solid groove structure. </p>
                    <br />
                    <button class="btn btn-outline-default btn-round"><i class="fa fa-cog"></i> Settings</button>
                </div>
            </div>
            <br/>
            <div class="nav-tabs-navigation">
                <div class="nav-tabs-wrapper">
                   
                </div>
            </div>
        </div>
    </div>
</div>