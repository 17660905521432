import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { sendMailDto } from '../models/sendMailDto';

const MAIL_URL = environment.mail.url;

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(
    private _http: HttpClient
  ) { }

  sendMail(mail : sendMailDto): Observable<string> {
    return this._http.post<string>(`${MAIL_URL}mail`, mail)
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse): Observable<never> {
    debugger;
    return throwError(() => error);
  }
}

