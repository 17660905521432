import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-scrollTop',
  templateUrl: './scroll.top.component.html',
  styleUrls: ['./scroll.top.component.scss']
})
export class ScrollTopComponent implements OnInit {

  constructor(
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit(): void {
  }

  /* 
  --------------- TO SCROLL BOTTOM --------------
  scrollBottom() {    
    this.viewportScroller.scrollToPosition([ 0, (window.document.children[0].scrollHeight - 300)])
  }*/

  scrollTop() {    
    this.viewportScroller.scrollToPosition([ 0, 0 ])
  }
}

/* 

---------------  INCLUDE THIS IN PARENT TS FILE ------------------

public isScrollTop: boolean = false;

ngOnInit() {

  this.renderer.listen('window', 'scroll', (event) => {
    const positionY > window.innerHeight) {
      this.isScrollTop = true;
    } else {
      this.isScrollTop = false;
    }
  })
}

---------------  INCLUDE THIS IN PARENT HTML FILE ------------------

<app-scrollTop *ngIf="isScrollTop"></app-scrollTop>

*/