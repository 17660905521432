import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-googleMaps',
  templateUrl: './google.maps.component.html',
  styleUrls: ['./google.maps.component.scss']
})
export class GoogleMapsComponent implements OnInit {

  latitude = environment.google.latitude;
  longitude = environment.google.longitude;
  mapType = environment.google.iniMapType;

  constructor() { }

  ngOnInit(): void {
  }

}
