// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  languages: {
    accepted: ["en", "es"],
    default: "es"
  },
  google: {
    mapsKey: "AIzaSyBZluk-PrU1hc_l80u6bt6FJlKgiIan73A",
    latitude:41.480623,
    longitude:2.047935,
    iniMapType:"roadmap"
  },
  social: {
    facebook: "",
    twitter: "",
    linkedIn: "https://linkedin.com",
  },
  mail: {    
    url: "https://api.techview.es/api/",
    company: "TechView",
    fromName: "TechView Solutions",
    fromEmail: "info@techview.es"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
