import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { trigger, style, transition, animate, state } from '@angular/animations';

@Component({
  selector: 'app-services',
  templateUrl: './kit_digital.component.html',
  styleUrls: ['./kit_digital.component.scss'],
  animations: [
    trigger('enterTop', [      
      transition(':enter', [
          style({ transform: 'translateY(-50vh)' }),
          animate('500ms ease')
      ])
    ]),
    trigger('enterRight', [
      state('closed', style({ transform: 'translateX(100vw)' })),
      state('open', style({ transform: 'translateX(0vw)'})),
      transition('* => open', [
        animate('1000ms ease')
      ])
    ]),  
    trigger('enterLeft', [
      state('closed', style({ transform: 'translateX(-100vw)' })),
      state('open', style({ transform: 'translateX(0vw)'})),
      transition('* => open', [
        animate('1000ms ease')
      ])
    ]),  
  ],
})
export class KitDigitalComponent implements OnInit {

  // animation variables //
  
  public titleState = false;
  public firstState = false;
  public secondState  = false;
  public thirdState  = false;
  public fourthState  = false;
  public fifthState  = false;
  public sixthState  = false;
  public seventhState  = false;
  public eighthState  = false;

  private defaultTimeOut = 250;
  private bodyRect;
  private thirdOffset;
  private fourthOffset;
  private fifthOffset;
  private sixthOffset;
  private seventhOffset;
  private eighthOffset;

  private screenHeight;
  private screenWidth;
  private jumpOffset;

  @ViewChild('first') private first: ElementRef;
  @ViewChild('second') private second: ElementRef;
  @ViewChild('third') private third: ElementRef;
  @ViewChild('fourth') private fourth: ElementRef;
  //@ViewChild('fifth') private fifth: ElementRef;
  //@ViewChild('sixth') private sixth: ElementRef;  
  //@ViewChild('seventh') private seventh: ElementRef;
  //@ViewChild('eighth') private eighth: ElementRef;

  constructor(
    private renderer : Renderer2, 
  ) { }

  ngOnInit(): void {

    setTimeout(()=>{ this.titleState = true;}, this.defaultTimeOut);
    setTimeout(()=>{ this.firstState = true;}, this.defaultTimeOut);
    setTimeout(()=>{ this.secondState = true;}, this.defaultTimeOut*2);

    this.renderer.listen('window', 'scroll', (event) => {
      const number = window.scrollY; 
      this.getPositions();

      if (number > (this.thirdOffset + this.jumpOffset - this.screenHeight)) {
        this.thirdState = true;  
      }

      if (number > (this.fourthOffset + this.jumpOffset - this.screenHeight)) {
        var timeOut = this.screenWidth > 760 ? this.defaultTimeOut : 1;
        setTimeout(()=>{ this.fourthState = true;}, timeOut);
      }
      if (number > (this.fifthOffset + this.jumpOffset - this.screenHeight)) {
        this.fifthState = true;  
      }

      if (number > (this.sixthOffset + this.jumpOffset - this.screenHeight)) {
        var timeOut = this.screenWidth > 760 ? this.defaultTimeOut : 1;
        setTimeout(()=>{ this.sixthState = true;}, timeOut);
      }

      if (number > (this.seventhOffset + this.jumpOffset - this.screenHeight)) {
        this.seventhState = true;  
      }

      if (number > (this.eighthOffset + this.jumpOffset - this.screenHeight)) {
        var timeOut = this.screenWidth > 760 ? this.defaultTimeOut : 1;
        setTimeout(()=>{ this.eighthState = true;}, timeOut);
      }
    })
  }

  getPositions() {
    
    this.bodyRect = document.body.getBoundingClientRect();    
    this.thirdOffset= this.third.nativeElement.getBoundingClientRect().top - this.bodyRect.top;
    this.fourthOffset = this.fourth.nativeElement.getBoundingClientRect().top - this.bodyRect.top;
    //this.fifthOffset = this.fifth.nativeElement.getBoundingClientRect().top - this.bodyRect.top;
    //this.sixthOffset = this.sixth.nativeElement.getBoundingClientRect().top - this.bodyRect.top;
    //this.seventhOffset = this.seventh.nativeElement.getBoundingClientRect().top - this.bodyRect.top;
    //this.eighthOffset = this.eighth.nativeElement.getBoundingClientRect().top - this.bodyRect.top;

    this.screenHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
    this.screenWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
    this.jumpOffset = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) / 4;
  }

}
