import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import {ViewportScroller} from '@angular/common'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],  
    animations: [
      trigger('fromTop', [      
        transition(':enter', [
          style({ transform: 'translatey(-130vh)' }),
          animate('500ms 1500ms ease',)
        ])
      ]),
    ]
})
export class MediaComponent implements OnInit {

  public facebookUrl = environment.social.facebook;
  public twitterUrl = environment.social.twitter;
  public linkedInUrl = environment.social.linkedIn;

  constructor() { } 

  ngOnInit(): void {
  } 

}
