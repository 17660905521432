import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from './../../../environments/environment';

const LNG_KEY = "SELECTED_LANGUAGE";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selected = "";
  private acceptedLanguages = environment.languages.accepted;
  private dafaultLanguage = environment.languages.default;

  constructor(
    private translate: TranslateService
  ) { }

  setInitialLanguage() {
    let language = this.translate.getBrowserLang();
    if ((this.acceptedLanguages.indexOf(language) == -1)) {
      language = this.dafaultLanguage;
    }
    this.translate.setDefaultLang(language);    

    var val;

    if (val = localStorage.getItem(LNG_KEY)) {       
        this.setLanguage(val);
        this.selected = val;    
    } 
  }

  getLanguages() {
    return [
      { text: 'English' , value: 'en', img: 'assets/images/en.png'},
      { text: 'Español' , value: 'es', img: 'assets/images/es.png'}
    ]
  }

  setLanguage(lng) {
    this.translate.use(lng);
    this.selected = lng;
    localStorage.setItem(LNG_KEY, lng);
  }

  getCurrentLanguage(){
    return this.translate.currentLang;
  }
}
