<nav class="navbar navbar-expand-lg fixed-top navbar-transparent" color-on-scroll="500">
    <div class="container">
        <div class="navbar-translate">
            <a class="navbar-brand" [routerLink]="['/home']">
                <div>
                    <img src="{{logoImage}}" class="header-logo-mini  navbar-brand-logo">                
                </div>
            </a>
            <button class="navbar-toggler navbar-burger" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" (click)="sidebarToggle()">
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
            </button>
        </div>
        <div class="navbar-collapse" id="navbarToggler">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item" >
                    <a [routerLink]="['/services']" class="nav-link" routerLinkActive="active">{{ 'navbar.services' | translate }}</a>
                </li>
                <!--<li class="nav-item" >
                    <a [routerLink]="['/projects']" class="nav-link" routerLinkActive="active">{{ 'navbar.projects' | translate }}</a>
                </li>-->
                <li class="nav-item" >
                    <a [routerLink]="['/kit_digital']" class="nav-link" routerLinkActive="active">{{ 'navbar.kitDigital' | translate }}</a>
                </li>
                <li class="nav-item" >
                    <a [routerLink]="['/about']" class="nav-link" routerLinkActive="active">{{ 'navbar.aboutUs' | translate }}</a>
                </li>
                <li class="nav-item" >
                    <a [routerLink]="['/work']" class="nav-link" routerLinkActive="active">{{ 'navbar.workWithUs' | translate }}</a>
                </li>                  
                <li class="nav-item" style="padding-left: 15px;" >
                    <a (click)="changeLanguage('es')">
                        <img src="./../../../../assets/imgs/flags/spain-icon.png" [ngClass]="lang == 'es' ? 'flag' : 'flag dark'">
                    </a>                 
                    <a (click)="changeLanguage('en')">
                        <img src="./../../../../assets/imgs/flags/united-kingdom-icon.png" [ngClass]="lang == 'en' ? 'flag' : 'flag dark'">
                    </a>
                </li>               
            </ul>
        </div>
    </div>
</nav>