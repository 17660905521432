import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { MediaComponent } from './shared/components/media/media.component';

import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ServicesComponent } from './services/services.component';
import { ProjectsComponent } from './projects/projects.component';
import { AboutUsComponent } from './about.us/about.us.component';
import { WorkWithUsComponent } from './work.with.us/work.with.us.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { GoogleMapsComponent } from './shared/google.maps/google.maps.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MailFormComponent } from './shared/components/mail.form/mail.form.component';
import { LegalComponent } from './shared/components/legal/legal.component';
import { PrivacyComponent } from './shared/components/privacy/privacy.component';
import { ScrollTopComponent } from './shared/components/scroll.top/scroll.top.component';
import { KitDigitalComponent } from './kit_digital/kit_digital.component';

export function HttpLoaderFactory (http: HttpClient) {
return new TranslateHttpLoader(http);
}

var mapsKey = environment.google.mapsKey;

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    MediaComponent,
    ServicesComponent,
    KitDigitalComponent,
    ProjectsComponent,
    AboutUsComponent,
    WorkWithUsComponent,
    GoogleMapsComponent,
    MailFormComponent,
    LegalComponent,
    PrivacyComponent,
    ScrollTopComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: mapsKey
    }),
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
