    <div class="wrapper" style="overflow-x: hidden;">
    <div @enterTop class="page-header page-header-xs" data-parallax="true" style="background-image: url('./../../assets/imgs/aboutus-image.png');">
        <div class="filter"></div>
    </div>
    <div class="main" style="overflow-y: hidden;">
        <div class="section text-center">
            <div class="container">
                <div #mainText class="row" style="margin-bottom: 50px;" @enterBottom>
                    <div class="col-md-8 mr-auto ml-auto">
                        <h2 class="title" style="margin-bottom: 80px;">{{ 'about.title' | translate }}</h2>
                        <h5 class="description" style="margin-bottom: 30px;">
                            {{ 'about.text1' | translate }}
                        </h5>
                        <h5 class="description">
                            {{ 'about.text2' | translate }}
                        </h5>
                        <br>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>